import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <h1>Hello!</h1>
    <p>Welcome to my site.</p>
    <p>You can find my resume and other info <Link to="/page-2/">here</Link></p>
    <StaticImage
      src="../images/IMG_1894_head.jpg"
      width={400}
      quality={95}
      formats={["auto", "webp", "avif"]}
      alt="A photo of me!"
      style={{ marginBottom: `1.45rem` }}
    />
  </Layout>
)

export default IndexPage
